.c-utils {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 3rem;
}

.c-updated {
  color: #6E7191;
  font-size: 1.6rem;
  font-style: italic;
  letter-spacing: 0.16px;
  line-height: 1.875;
  margin: 0;
}
