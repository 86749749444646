.c-stats {
  &__row {
    margin: 0 0 4.8rem;

    @media screen and (min-width: 48em) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__col {
    @media screen and (min-width: 48em) {
      flex: 1;
      margin: 0;
    }
  }

  &__name {
    color: #6E7191;
    flex: 0 0 30%;
    font-size: 1.4rem;
    letter-spacing: 0.26px;
    line-height: 1.64;
    margin: 0;
    text-transform: uppercase;
  }
}

.c-stat {
  margin: 0 0 0.8rem;

  @media screen and (min-width: 48em) {
    align-items: flex-start;
    display: flex;
  }

  &__name {
    color: #6E7191;
    flex: 0 0 30%;
    font-size: 1.4rem;
    letter-spacing: 0.26px;
    line-height: 1.64;
    margin: 0;
    text-transform: uppercase;
  }

  &__data {
    margin: 0;
  }

  &__link {
    color: #5663D6;
    display: block;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 1.05px;
    line-height: 1.64;
    text-transform: uppercase;

    & + .c-stat__link {
      margin: 1rem 0 0;
    }

    svg {
      margin: 0 0 0 0.2rem;
    }
  }

  &__expand {
    background: none;
    border: none;
    box-shadow: none;
    color: #3541AC;
    font-family: Cabin;
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.14px;
    line-height: 1.428;
    margin: 1rem 0 0;
    padding: 0;
    text-align: center;
    text-decoration: underline;
  }

  &__aka {
    color: #080F3D;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 0.16px;
    line-height: 1.375;

    & + .c-stat__aka {
      &:before {
        content: ', ';
      }
    }
  }

  @media print {
    svg,
    #genereview,
    #family-resources,
    #related-syndromes,
    #references {
      display: none;
    }
  }
}