.c-404 {
  padding: 12rem 0 10rem;

  &__heading {
    color: #3541AC;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.66;
    margin: 0;
  }

  &__text {
    color: #1F1F1F;
    font-size: 1.8rem;
    letter-spacing: 0.2px;
    line-height: 1.66;
    margin: 0;
    max-width: 59rem;
  }
}

