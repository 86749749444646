.c-syndrome {
  margin: 4.5rem 0 15rem;

  @media print {
    margin: 0;
  }

  &__name {
    color: #080F3D;
    font-size: 3.6rem;
    letter-spacing: 0.6px;
    line-height: 1.33;
    margin: 0 0 1.5rem;
  }
}
