.c-search {
  background: #061B70;
  border-radius: 0 0 0 8rem;
  box-shadow: 0 0 20px 0 rgba(8,15,61,0.3);
  margin: -10.9rem 0 0;
  padding: 4rem 2rem;
  position: relative;
  z-index: 3;

  @media screen and (min-width: 48em) {
    align-items: flex-start;
    display: flex;
    padding: 8.6rem 6rem;
  }

  &__item {
    margin: 0 0 1.6rem;

    .ant-select {
      width: 100%;

      .ant-select-selector {
        height: 4.5rem !important;
      }

      &.ant-select-focused {
        .ant-select-selector {
          outline: 0.2rem dotted #fff !important;
        }
      }
    }

    .ant-select-selection-placeholder {
      line-height: 44px !important;
    }

    &:nth-child(3) {
      text-align: right;
      margin: 0;
    }

    @media screen and (min-width: 48em) {
      margin: 0 1.2rem 0;

      &:nth-child(1) {
        flex: 2;
      }

      &:nth-child(2) {
        flex: 1;
      }

      &:nth-child(3) {
        flex: 0;
        margin: 0 1.2rem 0;
      }
    }
  }

  &__error {
    color: #fff;
    margin: 0.8rem 0 0;
  }

  &__btn {
    align-items: center;
    background-color: #FFA300;
    border-radius: 0.5rem;
    border: none;
    box-shadow: none;
    color: #061B70;
    display: inline-flex;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    line-height: 1.5625;
    padding: 1rem 3.3rem;
    text-align: center;
    text-transform: uppercase;

    svg {
      margin: 0 0.8rem 0 0;
    }

    &:focus {
      outline: 0.2rem dotted #fff;
    }
  }

  @media print {
    display: none;
  }
}
