.c-recommendations {
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 48em) {
    margin: 0 10rem;
  }

  &__heading {
    color: #080F3D;
    display: none;
    font-size: 2.2rem;
    letter-spacing: 0.2px;
    line-height: 1.18;
    margin: 0 0 2.4rem;

    @media print {
      display: block;
    }
  }
}

.c-recommendation {
  border: 0.1rem solid #F0F0F5;
  border-radius: 0.4rem 0.4rem 0.4rem 4rem;
  box-shadow: 0 0 2rem 0 #F0F0F5;
  margin: 0 0 2.5rem;
  padding: 2.5rem;

  @media screen and (min-width: 48em) {
    padding: 4.5rem;
  }

  &__name {
    color: #061B70;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.25;
  }

  &__stats {
    margin: 0;

    svg {
      margin: 0 1.5rem 0 0;
    }

    dt {
      align-items: center;
      color: #6E7191;
      display: flex;
      font-size: 1.6rem;
      letter-spacing: 0.89px;
      line-height: 1.5625;
      margin: 0 0.5rem 0 0;
      text-transform: uppercase;
    }

    dd {
      color: #080F3D;
      font-size: 1.6rem;
      letter-spacing: 0.16px;
      line-height: 1.875;
      margin: 0;
    }
  }

  &__stats-row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 1.2rem;

    &:last-child {
      margin: 0;
    }
  }
}

.c-recommendations-table {
  margin: 0 0 4.6rem;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 2rem;
  }

  &__heading {
    color: #080F3D;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 0.89px;
    line-height: 1.5625;
    text-transform: uppercase;
  }

  &__btn {
    background: none;
    border: none;
    box-shadow: none;
    color: #5663D6;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    line-height: 1.5625;
    text-align: center;
    text-transform: uppercase;

    svg {
      margin: 0 0 0 0.5rem;
    }
  }

  &__wrapper {
    overflow-x: scroll;
    width: 100%;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
    table-layout: fixed;

    @media screen and (min-width: 30em) {
      width: 100%;
    }

    thead {
      th {
        background: #080F3D;
        color: #F7FAFF;
        font-size: 1.6rem;
        font-weight: normal;
        letter-spacing: 1.5px;
        padding: 2rem;
        text-transform: uppercase;

        &:first-child {
          position: relative;

          &:after  {
            background: #F7FAFF;
            content: '';
            height: 60%;
            width: 0.1rem;
            position: absolute;
            right: 0.1rem;
            top: 20%;
          }
        }

        @media print {
          color: rgba(0, 0, 0, 0.85);
          font-size: 1.2rem;
          padding: 2rem 0.8rem;
        }

      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          td {
            border-bottom: 0.1rem solid #F0F0F5;
            border-top: 0.1rem solid #F0F0F5;

            &:first-child {
              border-left: 0.1rem solid #F0F0F5;
            }

            &:last-child {
              border-right: 0.1rem solid #F0F0F5;
            }
          }
        }

        &:nth-child(odd) {
          td {
            background: #F7FAFF;
          }
        }

        td {
          color: #080F3D;
          font-size: 1.6rem;
          letter-spacing: 0.4px;
          line-height: 1.44;
          padding: 2rem;
          vertical-align: top;

          &:first-child {
            position: relative;

            &:after  {
              background: #080F3D;
              bottom: -0.5rem;
              content: '';
              height: 105%;
              width: 0.1rem;
              position: absolute;
              right: 0.1rem;
              top: -0.5rem;
            }
          }

          em {
            color: #626262;
            display: block;
            font-style: italic;
            letter-spacing: 0.36px;
            line-height: 1.625;
            margin: 0.4rem 0 0;
          }

          @media print {
            font-size: 1.2rem;
            padding: 2rem 0.8rem;
          }
        }
      }
    }
  }

  @media print {
    &__btn {
      display: none;
    }

    table {
      width: 100%;
    }
  }
}
