.c-header {
  padding: 2.1rem 0;

  &__row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.c-dfci-logo {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  img, svg {
    height: auto;
    max-width: 100%;

    @media screen and (min-width: 22.750em) {
      max-width: 36.4rem;
    }
  }

  p {
    color: #585E79;
    font-size: 1.6rem;
    letter-spacing: 1.2px;
    margin: 1rem 0;
    text-transform: uppercase;

    @media screen and (min-width: 48em) {
      margin: 0 3rem 0 0;
    }
  }

  @media print {
    .c-logo {
      color: #000;
    }

    p {
      margin: 0 10px 0 0 !important;
    }

    img {
      width: 290px !important;
    }
  }
}
