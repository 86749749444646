.c-footer {
  background: #080F3D;
  padding: 4rem 0;
  text-align: left;

  p {
    color: #F7FAFF;
    font-size: 1.4rem;
    letter-spacing: 0.16px;
    line-height: 1.875;
    margin: 2.4rem 0 0;
  }

  @media print {
    a, img {
      display: none;
    }
  }
}
