.c-print {
  align-items: center;
  background: none;
  border: none;
  box-shadow: none;
  color: #5663D6;
  display: inline-flex;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1.2px;
  margin: 0 3.5rem 0 0;
  text-transform: uppercase;

  svg {
    margin: 0 0 0 0.5rem;
  }

  @media print {
    display: none;
  }
}
