.c-sort {
  border: 0.1rem solid #F0F0F5;
  border-radius: 3rem 0 0 3rem;
  background-color: #FBFBFB;
  margin: 0 0 3rem;
  padding: 2rem;

  @media screen and (min-width: 48em) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0 10rem 8rem;
    padding: 3.5rem;
  }

  .ant-select {
    margin: 0 0 0 auto;
    width: 100%;

    @media screen and (min-width: 48em) {
      width: auto;
    }
  }

  svg {
    margin: 0 1.6rem 0 0;
  }

  &__label {
    align-items: flex-start;
    color: #6E7191;
    display: flex;
    font-size: 1.6rem;
    letter-spacing: 1.5px;
    line-height: 1.375;
    margin: 0 0 2rem;
    text-transform: uppercase;

    @media screen and (min-width: 48em) {
      align-items: center;
      margin: 0;
    }
  }

  @media print {
    display: none;
  }
}
