.c-contact {
  background: #F7FAFF;
  padding: 6rem 0 10.2rem;

  &__row {
    @media screen and (min-width: 48em) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0 10rem;
    }
  }

  &__col {
    margin: 0 0 2rem;

    @media screen and (min-width: 48em) {
      flex: 0 1 auto;
      margin: 0;
    }
  }

  &__heading {
    color: #3541AC;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.66;
    margin: 0;
  }

  &__text {
    color: #1F1F1F;
    font-size: 1.8rem;
    letter-spacing: 0.2px;
    line-height: 1.66;
    margin: 0;
    max-width: 59rem;
  }

  &__btn {
    background: #061B70;
    border-radius: 0.5rem;
    border: none;
    box-shadow: none;
    color: #fff;
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    line-height: 1.5625;
    padding: 1rem 8rem;
    text-align: center;
    text-transform: uppercase;

    &:hover {

    }
  }

  @media print {
    display: none;
  }
}
