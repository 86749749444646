.c-timeline {
  min-width: 100rem;
  table-layout: fixed;

  @media screen and (min-width: 30em) {
    width: 100%;
  }

  @media print {
    color-adjust: exact;
    min-width: 0;
    width: 100%;
  }

  &__wrapper {
    overflow-x: scroll;
    width: 100%;
  }

  &__header {
    th {
      padding-bottom: 1.2rem;
    }
  }

  &__footer {
     th {
      padding-top: 1.2rem;
     }
  }

  &__body {
    border-bottom: 0.2rem solid #C5C5C5;
    border-top: 0.2rem solid #C5C5C5;
    margin: 1.2rem 0;

    td {
      padding: 2rem 0;
      position: relative;
      vertical-align: top;

      &:first-child {
        padding: 2rem;

        @media print {
          padding: 2rem 0.8rem;
        }
      }

      &:before {
        border-left: 1px dashed #E5E5E5;
        bottom: 0;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 1px;
      }

      &:first-child {
        .c-timeline__pill {
          border-top-left-radius: 18.5px;
          border-bottom-left-radius: 18.5px;
        }
      }

      &:last-child {
       .c-timeline__pill {
        border-top-right-radius: 18.5px;
        border-bottom-right-radius: 18.5px;
       }
      }
    }
  }

  &__label {
    color: #080F3D;
    font-weight: 600;
  }

  &__frequency {
    color: #707070;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.26px;
    line-height: 1.14;
    margin: 0.8rem 0 0 0.8rem;
  }

  &__caveats {
    color: #626262;
    display: block;
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 1.2;
    margin: 0.8rem 0 0 0.8rem;
  }

  &__heading {
    color: #080F3D;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 1.1875;
  }

  &__pill {
    background-color: #838FF5;
    display: block;
    height: 1.2rem;
    position: relative;
    width: 100%;
    z-index: 1;

    &--empty {
      background-color: #EEEEEE;
    }

    &--alt {
      background-color: #C5CBFF;
    }

    &--end {
      border-top-right-radius: 18.5px;
      border-bottom-right-radius: 18.5px;
    }

    &--start {
      border-top-left-radius: 18.5px;
      border-bottom-left-radius: 18.5px;
    }
  }

  &__image {
    display: none;
    flex: 0 0 auto;
    margin: 2.4rem 2.8rem 0 0;

    @media print {
      display: none;
    }

    @media screen and (min-width: 48em) {
      display: block;
    }

    &-wrapper {
      @media screen and (min-width: 48em) {
        display: flex;
      }
    }
  }
}
