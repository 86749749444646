.c-about {
  padding: 12rem 0 10rem;

  &__row {
    @media screen and (min-width: 48em) {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 0 0 0 10rem;
    }
  }

  &__col {
    &:first-child {
      margin: 0 0 4rem;
    }

    @media screen and (min-width: 48em) {
      flex: 0 1 auto;

      &:first-child {
        margin: 0 0 0 3rem;
      }
    }
  }

  &__image {
    margin: 0;
    position: relative;

    img {
      //border-radius: 0 8rem 0 0;
      //box-shadow: 0 0 2rem 0 rgba(10, 17, 63, 0.2);
      position: relative;
      width: 100%;
      z-index: 1;
    }

    // &:before {
    //   background: #FFA300;
    //   border-radius: 0.4rem 0.4rem 0.4rem 3rem;
    //   bottom: -2rem;
    //   content: '';
    //   height: 9.1rem;
    //   left: -1.4rem;
    //   position: absolute;
    //   width: 9.1rem;

    //   @media screen and (min-width: 48em) {
    //     bottom: -3rem;
    //     left: -3rem;
    //   }
    // }
  }

  &__heading {
    color: #080F3D;
    font-size: 1.6;
    letter-spacing: 1.5px;
    line-height: 1.375;
    margin: 0 0 1rem;
    text-transform: uppercase;
  }

  p:not([class]) {
    color: #080F3D;
    font-size: 1.6rem;
    letter-spacing: 0.16px;
    line-height: 1.875;
    max-width: 59rem;
    margin: 0 0 0.1rem;

    &:last-of-type {
      margin: 0 0 6rem;
    }
  }

  &__logo {
    max-width: 100%;

    @media screen and (min-width: 48em) {
      max-width: 48.5rem;
    }
  }
}
