@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,700;1,400;1,700&display=swap');

$font-primary: 'Cabin', sans-serif;

html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font: 400 1.6rem/1.2 $font-primary;
}

a {
  &:focus {
    outline: 0.1rem auto rgb(24, 144, 255);
  }
}

img, svg {
  height: auto;
  max-width: 100%;
}

.c-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.u-sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.o-container {
  margin: 0 auto;
  padding: 0 1.6rem;
  max-width: 124rem;
}
