.c-table-heading {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 3.4rem;

  @media print {
    margin: 0;
  }

  &__header {
    flex: 1;

    @media print {
      display: none;
    }
  }

  &__buttons {
    cursor: pointer;
    display: flex;
    flex: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    @media print {
      display: none;
    }

    li {
      margin: 0;
      padding: 0;

      &:first-child {
        button {
          border-radius: 0.3rem 0 0 0.3rem;
        }
      }

      &:last-child {
        button {
          border-radius: 0 3px 3px 0;
        }
      }
    }

    button {
      align-items: center;
      background-color: #FBFBFB;
      border: 0.1rem solid #F0F0F5;
      display: inline-flex;
      justify-content: center;
      height: 4.4rem;
      width: 5.6rem;

      svg {
        cursor: pointer;
        fill: #080F3D;
      }

      &[disabled] {
        background-color: #6E7191;

        svg {
          fill: #fff;
        }
      }
    }
  }
}