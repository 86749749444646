.c-hero {
  padding: 10rem 0 15.5rem;
  position: relative;

  &:before {
    background: rgba(#080F3D, 0.6);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__image {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center left;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  &__content {
    position: relative;
    z-index: 2;

    h1 {
      color: #F7FAFF;
      font-size: 3.8rem;
      font-weight: 700;
      letter-spacing: 0.29px;
      line-height: 1.25;
      margin: 0 0 1.8rem;
      max-width: 76rem;
    }

    p {
      color: #F7FAFF;
      font-size: 1.6rem;
      letter-spacing: 1.5px;
      line-height: 1.875;
      margin: 0;
      max-width: 60rem;
      text-transform: uppercase;
    }
  }

  @media print {
    display: none;
  }
}
